/* eslint-disable vue/no-deprecated-v-on-native-modifier */
<template>
  <div class="container-fluid">
    <div class="login-box">
      <div class="user-container" @click="toPage('index')">
        <svg-icon icon-class="new-logo" class-name="login-logo"></svg-icon>
      </div>
      <!-- 手机号登录 -->
      <el-form
        v-show="
          isBingMobile == false && isWxLogin == false && isPwdLogin == false
        "
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        class="login-form"
      >
        <el-form-item label="" prop="mobile">
          <div class="verify-wrapper mobile-input">
            <el-row>
              <el-col :span="4">
                <el-input
                  size="large"
                  v-model="ruleForm.regionCode"
                  placeholder="86"
                  @input="submitMobile"
                  class="mobile-code"
              /></el-col>
              <el-col :span="20">
                <el-input
                  size="large"
                  v-model="ruleForm.mobile"
                  placeholder="输入手机号"
                  input-style=""
                  @input="submitMobile"
              /></el-col>
            </el-row>
          </div>
        </el-form-item>
        <transition name="el-zoom-in-top">
          <el-form-item
            label=""
            prop="afsSms"
            class="afs-sms"
            v-show="afsSmsShow"
          >
            <div id="your-dom-id" class="nc-container"></div>
          </el-form-item>
        </transition>

        <el-form-item label="" prop="smsCode">
          <div class="verify-wrapper captchaCode-input">
            <el-input
              size="large"
              link
              ref="sms"
              maxlength="6"
              suffix-icon="el-icon-lock"
              placeholder="输入短信码"
              v-model="ruleForm.smsCode"
            >
              <template #append>
                <el-button
                  class="btn-orange"
                  :disabled="disabled"
                  @click="resetNc"
                  >{{ valiBtn }}</el-button
                >
              </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="submit-btn"
            @click.prevent="onSubmit('ruleForm')"
            >登录/注册</el-button
          >
        </el-form-item>
        <div class="fastlogin">使用快捷登录</div>
        <div class="login-thbox">
          <el-image
            :src="require('@/assets/password.png')"
            class="login-btnimg"
            @click="isPwdLogin = true"
          ></el-image>
          <el-image
            :src="require('@/assets/new-wechat.png')"
            class="login-btnimg"
            @click="
              () => {
                this.isBingMobile = false;
                this.isWxDialog = true;
                this.isPwdLogin = false;
              }
            "
          ></el-image>
        </div>
        <div style="width: 200px; margin: 0 auto; text-align: center">
          <div
            class="login-thbox"
            style="
              width: 100px;
              margin: 0px 0px 20px 0;
              height: 12px;
              float: left;
            "
          >
            <span class="demonstration">密码登录</span>
          </div>
          <div
            class="login-thbox"
            style="width: 100px; margin: 0 0 20px 0; height: 12px; float: right"
          >
            <span class="demonstration">微信登录</span>
          </div>
        </div>
      </el-form>
      <!-- 密码登录 -->
      <el-form
        v-show="
          isBingMobile == false && isWxLogin == false && isPwdLogin == true
        "
        ref="ruleForm3"
        :model="ruleForm3"
        :rules="rules3"
        class="login-form"
      >
        <el-form-item label="" prop="mobile">
          <div class="verify-wrapper mobile-input">
            <el-input
              size="large"
              v-model="ruleForm3.accountName"
              placeholder="输入用户名"
              input-style=""
            />
          </div>
        </el-form-item>
        <el-form-item label="" prop="mobile">
          <div class="verify-wrapper mobile-input">
            <el-input
              size="large"
              v-model="ruleForm3.password"
              placeholder="输入密码"
              type="password"
              minlength="8"
              maxlength="16"
              show-password
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="submit-btn"
            @click.prevent="onSubmit('ruleForm3')"
            >登录</el-button
          >
        </el-form-item>
        <div class="fastlogin">使用快捷登录</div>
        <div class="login-thbox">
          <el-image
            :src="require('@/assets/mobile.png')"
            class="login-btnimg"
            @click="isPwdLogin = false"
          ></el-image>
          <el-image
            :src="require('@/assets/new-wechat.png')"
            class="login-btnimg"
            @click="
              () => {
                this.isBingMobile = false;
                this.isWxDialog = true;
                this.isPwdLogin = false;
              }
            "
          ></el-image>
        </div>
        <div style="width: 200px; margin: 0 auto; text-align: center">
          <div
            class="login-thbox"
            style="
              width: 100px;
              margin: 0px 0px 20px 0;
              height: 12px;
              float: left;
            "
          >
            <span class="demonstration">手机登录</span>
          </div>
          <div
            class="login-thbox"
            style="width: 100px; margin: 0 0 20px 0; height: 12px; float: right"
          >
            <span class="demonstration">微信登录</span>
          </div>
        </div>
      </el-form>
      <!-- 微信登录 -->
      <div
        v-show="
          isBingMobile == false && isWxLogin == true && isPwdLogin == false
        "
      >
        <div id="login_container" class="login-form wx-form"></div>
        <div class="fastlogin">或</div>
        <div
          class="login-thbox"
          style="
            width: 378px;
            height: 40px;
            margin: 20px 0 9px 0;
            display: block;
            text-align: center;
          "
        >
          <el-image
            :src="require('@/assets/password.png')"
            class="login-btnimg"
            @click="
              () => {
                this.isBingMobile = false;
                this.isWxLogin = false;
                this.isPwdLogin = true;
              }
            "
          ></el-image>
          <el-image
            :src="require('@/assets/mobile.png')"
            class="login-btnimg"
            @click="
              () => {
                this.isBingMobile = false;
                this.isWxLogin = false;
              }
            "
          ></el-image>
          <!-- <el-image
            :src="require('@/assets/qq.png')"
            class="login-btnimg"
          ></el-image> -->
        </div>
        <div style="width: 200px; margin: 0 auto; text-align: center">
          <div
            class="login-thbox"
            style="
              width: 100px;
              margin: 0px 0px 20px 0;
              height: 12px;
              float: left;
            "
          >
            <span class="demonstration">密码登录</span>
          </div>
          <div
            class="login-thbox"
            style="width: 100px; margin: 0 0 20px 0; height: 12px; float: right"
          >
            <span class="demonstration">手机登录</span>
          </div>
        </div>
      </div>
      <!-- 微信绑定手机 -->
      <el-form
        v-show="isBingMobile == true"
        ref="ruleForm2"
        :model="ruleForm2"
        :rules="rules2"
        class="login-form"
      >
        <el-form-item label="" prop="mobile">
          <div class="verify-wrapper mobile-input">
            <el-row>
              <el-col :span="4">
                <el-input
                  size="large"
                  v-model="ruleForm2.regionCode"
                  placeholder="86"
                  class="mobile-code"
              /></el-col>
              <el-col :span="20">
                <el-input
                  size="large"
                  v-model="ruleForm2.mobile"
                  placeholder="输入手机号"
                  input-style=""
              /></el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item label="" prop="smsCode">
          <div class="verify-wrapper captchaCode-input">
            <el-input
              size="large"
              link
              ref="sms"
              maxlength="6"
              suffix-icon="el-icon-lock"
              placeholder="输入短信码"
              v-model="ruleForm2.smsCode"
              input-style=""
            >
              <template #append>
                <el-button
                  class="btn-orange"
                  :disabled="disabled"
                  @click="getBindMobileSmsCode"
                  >{{ valiBtn }}</el-button
                >
              </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="submit-btn"
            @click.prevent="onSubmit('ruleForm2')"
            style="width: 100%"
            >绑定手机号</el-button
          >
        </el-form-item>
        <div class="fastlogin" @click="goBack" style="cursor: pointer">
          放弃绑定返回首页
        </div>
      </el-form>
      <div class="agree">
        登录或注册代表您同意<span @click="toPage('agreement')">用户条款</span>
        <span @click="toPage('privacy')">隐私政策</span>
      </div>
    </div>
  </div>
  <div class="wxinfo-dialog">
    <el-dialog v-model="isWxDialog" title="特别提醒" width="450px" center>
      <p>
        1.如果您已经手机注册过且想绑定微信，<span style="color: red"
          >您需要使用手机号登录后在设置中绑定微信</span
        >
      </p>
      <p>
        2.如果您没有手机注册或者微信注册过，会直接帮您注册新账号，并且需要您绑定手机
      </p>
      <p>3.如果您手机注册过且微信也注册过，并且想将他们绑定，请联系管理员</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isWxDialog = false" class="mobile-btn"
            >手机注册/登录</el-button
          >
          <el-button type="success" @click="wxGetCode" class="mobile-btn2">
            微信注册/登录
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import SvgIcon from "../../../svgIcon/index.vue";
export default {
  components: { SvgIcon },
  data() {
    return {
      captcha: "",
      ruleForm: {
        regionCode: "",
        mobile: "",
        captchaCode: "",
        smsCode: "",
        checked: true,
      },
      ruleForm2: {
        regionCode: "",
        mobile: "",
        smsCode: "",
        checked: true,
      },
      ruleForm3: {
        accountName: "",
        password: "",
      },
      rules: {
        mobile: [{ message: "请输入手机号码", trigger: "blur" }],
        // captchaCode: [
        //   { required: true, message: "请输入验证码", trigger: "blur" },
        // ],
        smsCode: [{ required: true, message: "请输入短信码", trigger: "blur" }],
        // checked: [{ required: true, message: "请勾选协议", trigger: "change" }],
      },
      rules2: {
        mobile: [{ message: "请输入手机号码", trigger: "blur" }],
        smsCode: [{ required: true, message: "请输入短信码", trigger: "blur" }],
      },
      rules3: {
        accountName: [{ message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      autoLogin: false,
      valiBtn: "获取短信码",
      disabled: false,
      time: 0,
      timeOver: false,
      isWxLogin: !!localStorage.getItem("__is_wx_login"),
      code: null,
      isWxDialog: false,
      isBingMobile: !!localStorage.getItem("__is_bing_mobile"),
      timeWxBind: null,
      isStarting: false,
      fromPath: "",
      nc: null,
      afsSmsShow: false,
      params: {
        regionCode: null,
        mobile: null,
        afsInfo: {
          sessionId: null,
          sig: null,
          token: null,
          scene: null,
        },
      },
      isMobileAccount: "false",
      isWechatAccount: "false",
      mindmap_token: "",
      isPwdLogin: !!localStorage.getItem("__is_pwd_login"),
    };
  },
  watch: {
    isBingMobile(val) {
      if (val) {
        localStorage.setItem("__is_bing_mobile", val);
      } else {
        localStorage.removeItem("__is_bing_mobile");
      }
    },
    isWxLogin(val) {
      if (val) {
        localStorage.setItem("__is_wx_login", val);
      } else {
        localStorage.removeItem("__is_wx_login");
      }
    },
    isPwdLogin(val) {
      if (val) {
        localStorage.setItem("__is_pwd_login", val);
      } else {
        localStorage.removeItem("__is_pwd_login");
      }
    },
  },
  created() {
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.id = "scriptWx";
    // script.src = "//res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    // document.body.appendChild(script);
    if (localStorage.getItem("isMobileAccount")) {
      this.isMobileAccount = localStorage.getItem("isMobileAccount");
    }
    if (localStorage.getItem("isWechatAccount")) {
      this.isWechatAccount = localStorage.getItem("isWechatAccount");
    }
  },
  beforeUnmount() {
    // document.getElementById("scriptnc").remove();
    window.removeEventListener("message", this.handleMessage);
  },
  mounted() {
    window.addEventListener("message", this.handleMessage);
    this.newNc();
    if (this.isMobileAccount == "false" && this.isWechatAccount == "true") {
      this.isBingMobile = true;
    }
    if (this.isWxLogin) {
      this.wxGetCode();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // console.log(from, "from");
      //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.fromPath = from.path; //获取上一级路由的路径
    });
  },
  methods: {
    saveStorage() {},
    newNc() {
      var that = this;
      var nc_token = [
        "FFFF0N0000000000B211",
        new Date().getTime(),
        Math.random(),
      ].join(":");
      var NC_Opt = {
        renderTo: "#your-dom-id",
        appkey: "FFFF0N0000000000B211",
        scene: "nc_login",
        token: nc_token,
        customWidth: 300,
        trans: { key1: "code0" },
        elementID: ["usernameID"],
        is_Opt: 0,
        language: "cn",
        isEnabled: true,
        timeout: 3000,
        times: 5,
        apimap: {
          // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
          // 'get_captcha': '//b.com/get_captcha/ver3',
          // 'get_captcha': '//pin3.aliyun.com/get_captcha/ver3'
          // 'get_img': '//c.com/get_img',
          // 'checkcode': '//d.com/captcha/checkcode.jsonp',
          // 'umid_Url': '//e.com/security/umscript/3.2.1/um.js',
          // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
          // 'umid_serUrl': 'https://g.com/service/um.json'
        },
        callback: function (data) {
          that.params.afsInfo.sessionId = data.csessionid;
          that.params.afsInfo.sig = data.sig;
          that.params.afsInfo.token = nc_token;
          that.params.afsInfo.scene = NC_Opt.scene;
          that.getAfsSms();
        },
      };
      this.nc = new noCaptcha(NC_Opt);
      this.nc.upLang("cn", {
        _startTEXT: "请按住滑块，拖动到最右边",
        _yesTEXT: "验证通过",
        _error300:
          '出错了，点击<a href="javascript:__nc.reset()">刷新</a>再来一次',
        _errorNetwork:
          '网络不给力，请<a href="javascript:__nc.reset()">点击刷新</a>',
      });
    },
    resetNc() {
      this.nc.reset();
    },
    submitMobile() {
      // if (this.ruleForm.mobile.length == 11) {
      // this.getCaptcha();
      this.afsSmsShow = true;
      this.params.regionCode = this.ruleForm.regionCode;
      this.params.mobile = this.ruleForm.mobile;
      // }
    },
    // 获取验证码
    // 获取验证码 并只验证手机号 是否正确
    getCaptcha() {
      // 获取图片验证码;
      this.$api.account.captcha(this.ruleForm.mobile).then((res) => {
        this.captcha =
          "data:image/png;base64," +
          btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
      });
      // this.$refs.ruleForms.validate("mobile", (err) => {
      //   console.log(err);
      //   if (err) {
      //     this.$message.error("填写正确手机号码");
      //   } else {
      //     // 获取图片验证码;
      //     this.$api.account.captcha(this.ruleForm.mobile).then((res) => {
      //       this.captcha =
      //         "data:image/png;base64," +
      //         btoa(
      //           new Uint8Array(res.data).reduce(
      //             (data, byte) => data + String.fromCharCode(byte),
      //             ""
      //           )
      //         );
      //     });
      //   }
      // });
    },
    enterBlur(event) {
      this.$refs.captcha.focus();
    },
    enterBlur2(event) {
      this.$refs.sms.focus();
    },
    getAfsSms() {
      // if (this.params.mobile == null || this.params.mobile.length != 11) {
      //   this.tips("error", "请输入11位手机号");
      //   return false;
      // }
      this.$api.account.sendAfsSms(this.params).then((res) => {
        if (res.data.isSuccess == true) {
          this.tackBtn(); // 短信码倒数60秒
          this.tips("success", "短信已发送");
        } else {
          this.tips("error", res.data.message);
        }
      });
    },

    getSmsCode() {
      // 获取手机短信码
      this.$api.account
        .sendSms({
          regionCode: this.ruleForm.regionCode,
          mobile: this.ruleForm.mobile,
          captchaCode: this.ruleForm.captchaCode,
        })
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.tackBtn(); // 短信码倒数60秒
            this.tips("success", "短信已发送");
          } else {
            this.tips("error", res.data.message);
          }
        });

      // this.$refs["ruleForms"].validateField("mobile", (err) => {
      //   if (err) {
      //     this.$message.error("填写正确手机号码");
      //   } else {
      //     this.$refs["ruleForm"].validateField("captchaCode", (err) => {
      //       if (err) {
      //         this.$message.error("输入验证码");
      //       } else {
      //         // 获取手机短信码
      //         this.$api.account
      //           .sendSms({
      //             mobile: this.ruleForm.mobile,
      //             captchaCode: this.ruleForm.captchaCode,
      //           })
      //           .then((res) => {
      //             if (res.data.isSuccess == true) {
      //               this.tackBtn(); // 短信码倒数60秒
      //               this.$message.success("短信已发送");
      //             } else {
      //               this.$message.error(res.data.message);
      //             }
      //           });
      //       }
      //     });
      //   }
      // });
    },
    tackBtn() {
      // 验证码倒数60秒
      this.time = 59;
      this.timeOver = false;
      this.valiBtn = this.time + "秒后重试";
      let timer = setInterval(() => {
        if (this.time === 0) {
          clearInterval(timer);
          this.valiBtn = "获取短信码";
          this.disabled = false;
          this.timeOver = true;
        } else {
          this.disabled = true;
          this.valiBtn = this.time + "秒后重试";
          this.time--;
        }
      }, 1000);
    },
    loginSuccess() {
      const href = localStorage.getItem("preRoute");
      if (href && href !== "/login") {
        localStorage.removeItem("preRoute");
        window.location.href = href;
      } else {
        this.$router.push("/article");
      }
    },
    onSubmit(formName) {
      // 点击登录 验证手机& 验证码是否符合条件
      const testLogin = false;
      if (testLogin) {
        let res = {
          data: {
            code: 0,
            data: {
              accountCompletenessInfo: {
                isMobileAccount: true,
                isWechatAccount: false,
              },
              expireTime: "2023-06-18 00:00:00",
              token:
                "xPoAIjMQYqqIpko940qef7HK56N8Ht312cvwJyeMIXBoNxLRyGJ9FTQFp2ZT4C64",
            },
            isSuccess: true,
            message: "成功",
          },
        };
        this.ruleForm.mobile = "13501966049";
        if (res.data.isSuccess === true) {
          this.$store.commit("delToken");
          this.$store.commit("setMobile", this.ruleForm.mobile);
          this.$store.commit("setToken", res.data.data.token);
          this.$store.commit(
            "setMobileAccount",
            res.data.data.accountCompletenessInfo.isMobileAccount
          );
          this.$store.commit(
            "setWechatAccount",
            res.data.data.accountCompletenessInfo.isWechatAccount
          );
          this.$store.commit("startLogin");
          this.tips("success", "登录成功");
          // this.loginSuccess();
        }
        return;
      }
      this.$refs[formName].validate((valid) => {
        // 为表单绑定验证功能
        if (valid) {
          // 登录;
          if (formName === "ruleForm") {
            this.$api.account
              .mobileLogin({
                regionCode: this.ruleForm.regionCode,
                mobile: this.ruleForm.mobile,
                smsCode: this.ruleForm.smsCode,
              })
              .then((res) => {
                if (res.data.isSuccess === true) {
                  this.$store.commit("delToken");
                  this.$store.commit("setMobile", this.ruleForm.mobile);
                  this.$store.commit(
                    "setMobileAccount",
                    res.data.data.accountCompletenessInfo.isMobileAccount
                  );
                  this.$store.commit(
                    "setWechatAccount",
                    res.data.data.accountCompletenessInfo.isWechatAccount
                  );
                  this.$store.commit("setToken", res.data.data.token);
                  this.$store.commit("startLogin");
                  // this.loginSuccess();
                  this.tips("success", "登录成功");
                } else {
                  if (
                    this.timeOver == true &&
                    res.data.message == "手机验证码错误"
                  ) {
                    this.tips("error", "手机验证码超时，请重新获取");
                  } else {
                    this.tips("error", res.data.message);
                  }
                }
              });
          } else if (formName == "ruleForm2") {
            this.$api.account
              .bindMobile({
                regionCode: this.ruleForm2.regionCode,
                mobile: this.ruleForm2.mobile,
                smsCode: this.ruleForm2.smsCode,
              })
              .then((res) => {
                // console.log(res);
                if (res.data.isSuccess == true) {
                  this.$store.commit("setMobileAccount", true);
                  this.$store.commit("setWechatAccount", true);
                  this.$store.commit("setToken", mindmap_token);
                  this.tips("success", res.data.message);
                  this.$store.commit("startLogin");
                  // this.loginSuccess();
                } else {
                  if (res.data.message == "手机号已被绑定") {
                    this.tips("error", "手机号已注册/已被绑定");
                  } else if (res.data.code === 401) {
                    this.isBingMobile = false;
                    this.$store.commit("delToken");
                    this.$proxy.clear();
                  } else {
                    this.tips("error", res.data.message);
                  }
                }
              });
          } else if (formName == "ruleForm3") {
            var regexpName = /^[a-zA-Z0-9_-]{8,16}$/;
            if (!regexpName.test(this.ruleForm3.accountName)) {
              // this.$refs.accountName.focus();
              return this.tips(
                "error",
                "输入由英文字母或数字组成的8~16位用户名"
              );
            }
            var regexpP = /^[^\u4e00-\u9fa5]+$/;
            if (
              !regexpP.test(this.ruleForm3.password) ||
              this.ruleForm3.password.length < 8 ||
              this.ruleForm3.password.length > 16
            ) {
              // this.$refs.password.focus();
              return this.$root.tips("error", "输入正确的密码");
            }
            this.$api.account
              .pwdLogin({
                accountName: this.ruleForm3.accountName,
                password: this.ruleForm3.password,
              })
              .then((res) => {
                if (res.data.isSuccess === true) {
                  this.$store.commit("delToken");
                  this.$store.commit(
                    "setMobileAccount",
                    res.data.data.tokenInfo.accountCompletenessInfo
                      .isMobileAccount
                  );
                  this.$store.commit(
                    "setWechatAccount",
                    res.data.data.tokenInfo.accountCompletenessInfo
                      .isWechatAccount
                  );
                  this.$store.commit("setToken", res.data.data.tokenInfo.token);
                  this.$store.commit("startLogin");
                  // this.loginSuccess();
                  this.tips("success", "登录成功");
                } else {
                  this.tips("error", res.data.message);
                }
              });
          }
        } else {
          return false;
        }
      });
    },
    wxGetCode() {
      this.isWxDialog = false;
      this.isWxLogin = true;
      // var redirect_uri = encodeURIComponent(window.location.origin + "/wechat");
      var redirect_uri = encodeURIComponent(
        "https://wx-login.moxingshu.com.cn/wechat"
      );
      // console.log(redirect_uri);
      setTimeout(() => {
        var obj = new WxLogin({
          self_redirect: true,
          id: "login_container",
          appid: "wx20ce863c038c889c",
          scope: "snsapi_login",
          redirect_uri: redirect_uri,
          state: "123131",
          style: "",
          // href: "https://www.moxingshu.cn/pc/css/wechat.css",
          href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7CiAgd2lkdGg6IDI4MHB4Owp9Ci5pbXBvd2VyQm94IC50aXRsZSB7CiAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAuaW5mbyB7CiAgd2lkdGg6IDI4MHB4Owp9Ci5pbXBvd2VyQm94IC5zdGF0dXMgcCB7CiAgcGFkZGluZzogMHB4ICFpbXBvcnRhbnQ7CiAgbWFyZ2luOiAwcHggIWltcG9ydGFudDsKICBkaXNwbGF5OiBpbmxpbmU7CiAgY29sb3I6I2ZmZjsKICBmb250LXNpemU6IDEycHg7Cn0KLmltcG93ZXJCb3ggLnN0YXR1cyB7CiAgdGV4dC1hbGlnbjogY2VudGVyOwp9Cg==",
        });
      }, 100);
    },
    handleMessage(e) {
      // console.log(e.data);
      var data = e.data;
      switch (data.cmd) {
        case "returnInfo":
          if (data.params.code) {
            this.wxLogin(data.params.code);
            // var container = document.getElementsByClassName("container-fluid");
            // container[0].style.cssText =
            //   "background: #eeeef3 url(/pc/bg.png) no-repeat;";
          }
          break;
      }
    },
    wxLogin(code) {
      if (this.timeWxBind) {
        clearTimeout(this.timeWxBind);
      }
      //自己去做登录动画
      this.isStarting = true;
      this.$root.loginProgress = 0;
      this.timeWxBind = setTimeout(() => {
        this.$api.account.webWechatLogin({ code: code }).then((res) => {
          if (res.data.isSuccess === true) {
            this.$store.commit("delToken");
            if (
              res.data.data.accountCompletenessInfo.isMobileAccount === true
            ) {
              this.$store.commit(
                "setMobileAccount",
                res.data.data.accountCompletenessInfo.isMobileAccount
              );
              this.$store.commit(
                "setWechatAccount",
                res.data.data.accountCompletenessInfo.isWechatAccount
              );
              this.$store.commit("setToken", res.data.data.token);
              this.tips("success", "登录成功");
              this.$store.commit("startLogin");
              // this.loginSuccess();
            } else {
              this.$root.loginProgress = -1;
              this.tips("warning", "请绑定手机");
              this.isBingMobile = true;
            }
          } else {
            this.$root.loginProgress = -1;
            if (res.data.message !== "系统繁忙，请稍后再试。") {
              this.tips("error", res.data.message);
            }
            this.isStarting = false;
            this.isWxLogin = false;
          }
        });
      }, 0);
    },
    getBindMobileSmsCode() {
      // 获取手机短信码
      this.$api.account
        .sendBindMobileSms({
          regionCode: this.ruleForm2.regionCode,
          mobile: this.ruleForm2.mobile,
        })
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.tackBtn(); // 短信码倒数60秒
            this.tips("success", "短信已发送");
          } else {
            this.tips("error", res.data.message);
          }
        });

      // this.$refs["ruleForms"].validateField("mobile", (err) => {
      //   if (err) {
      //     this.$message.error("填写正确手机号码");
      //   } else {
      //     this.$refs["ruleForm"].validateField("captchaCode", (err) => {
      //       if (err) {
      //         this.$message.error("输入验证码");
      //       } else {
      //         // 获取手机短信码
      //         this.$api.account
      //           .sendSms({
      //             mobile: this.ruleForm.mobile,
      //             captchaCode: this.ruleForm.captchaCode,
      //           })
      //           .then((res) => {
      //             if (res.data.isSuccess == true) {
      //               this.tackBtn(); // 短信码倒数60秒
      //               this.$message.success("短信已发送");
      //             } else {
      //               this.$message.error(res.data.message);
      //             }
      //           });
      //       }
      //     });
      //   }
      // });
    },
    toPage(page = "index") {
      this.$router.push("/" + page).then(() => {
        window.scrollTo(0, 0);
      });
    },
    // 提示信息
    tips(type, message) {
      if (this.timeOutTips) {
        window.clearTimeout(this.timeOutTips);
      }
      this.timeOutTips = window.setTimeout(() => {
        this.$message({
          message: message,
          type: type,
          grouping: true,
        });
      }, 500);
    },
    goBack() {
      this.isBingMobile = false;
      this.$store.commit("delToken");
      this.$proxy.clear();
      this.toPage("index");
    },
  },
};
</script>

<style scoped>
.container-fluid {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100%;
  /* background: #030b21 url("//moxingshu.oss-cn-shanghai.aliyuncs.com/image/89796f0779964f5f900678907fc694dc.png") right top no-repeat; */
  /* background-size: 75%; */
  background: #000;
}

.login-box {
  position: relative;
  background: #000;
  /* border: 1px solid #6b6b6b; */
  border-radius: 20px;
  top: 40%;
  left: 50%;
  width: 378px;
  height: 560px;
  margin-left: -190px;
  margin-top: -232px;
}

.user-container {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 30px;
}

.login-logo {
  width: 200px;
  height: 65px;
}

.user-container-nick {
  padding: 0 10px;
  color: #109adc;
  font-size: 34px;
}

.login-txt {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  font-weight: 500;
}

.login-form {
  width: 316px;
  padding-left: 32px;
  margin-top: 40px;
  box-sizing: unset !important;
}
.wx-form {
  width: 378px;
  padding-left: 0;
  text-align: center;
  margin-top: 10px;
  box-sizing: unset !important;
  height: 345px;
}

.login-thbox {
  width: 316px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}
.login-thbox .demonstration {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}
.login-checked {
  text-align: center;
}

.captcha {
  width: 72px;
  height: 32px;
  line-height: 34px;
  text-align: center;
  font-size: 12px;
  color: #1380ff;
}

.btn-orange {
  font-size: 12px;
  color: #4d4d4d !important;
  width: 114px;
}

.login-btnimg {
  margin: 0 30px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

:deep .el-input-group__append {
  background: #141414 !important;
}
:deep .el-input__inner {
  color: #fff;
  background: #141414 !important;
}
.verify-wrapper {
  width: 100%;
  background: #141414;
  border-radius: 8px;
}

:deep .el-input__wrapper {
  background: #141414 !important;
}

.mobile-input :deep .el-input__wrapper {
  background: #141414 !important;
  border-radius: 8px;
  box-shadow: none;
}
.mobile-code :deep .el-input__wrapper {
  padding: 1px 0px 1px 15px;
}

.captchaCode-input :deep .el-input__wrapper {
  background: #141414 !important;
  border-radius: 8px 0 0 8px;
  box-shadow: none;
}

.captchaCode-input :deep .el-input-group__append {
  border-radius: 0 8px 8px 0;
  box-shadow: none;
}

:deep .el-form-item {
  margin-bottom: 24px !important;
}

:deep .el-form-item__error {
  padding-top: 4px !important;
  padding-left: 15px !important;
}

.fastlogin {
  font-size: 12px;
  text-align: center;
  color: #ffffff99;
}

.submit-btn {
  width: 100%;
  background: #333333;
  border-radius: 8px;
  border: 0;
  height: 40px;
  color: #ddd;
}

.agree {
  /* position: absolute;
  left: 0;
  bottom: 0; */
  width: 378px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  color: #ffffff99;
  /* background: #232f43; */
  border-radius: 0px 0px 20px 20px;
}

.agree span {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
}

.wxinfo-dialog :deep .el-dialog {
  background: #000;
  border: 1px solid #ffffff40;
  border-radius: 12px;
}
.wxinfo-dialog :deep .el-dialog__header {
  text-align: left;
}
.wxinfo-dialog :deep .el-dialog__body {
  padding: 0 16px 6px 16px;
  font-size: 12px;
}
.wxinfo-dialog :deep .el-dialog__footer {
  text-align: right;
}
.wxinfo-dialog :deep .el-dialog__title,
.wxinfo-dialog :deep .el-dialog__body {
  color: #ebebeb;
}

.mobile-btn {
  border-radius: 8px !important;
  border: 1px solid #fff !important;
  background: #000 !important;
  color: #ddd !important;
}

.mobile-btn:hover {
  background: #000 !important;
}
.mobile-btn2 {
  border-radius: 8px !important;
  border: 1px solid #333 !important;
  background: #333 !important;
  color: #ddd !important;
}
.mobile-btn2:hover {
  background: #333 !important;
}
:deep .nc_scale {
  background: #141414 !important;
  height: 40px !important;
  border-radius: 8px !important;
  /* 默认背景色 */
}

/* :deep .nc-container #nc_1_wrapper,
:deep .nc-container.tb-login #nc_1_wrapper,
:deep .nc-container #nc_2_wrapper,
:deep .nc-container.tb-login #nc_2_wrapper {
  width: 316px !important;
  border-radius: 8px !important;
} */
:deep .nc-container div[id*="_wrapper"],
:deep .nc-container.tb-login div[id*="_wrapper"] {
  width: 316px !important;
  border-radius: 8px !important;
}

:deep .nc_scale div.nc_bg {
  background: #333333 !important;
  border-radius: 8px 0 0 8px !important;
  /* 滑过时的背景色 */
}

:deep .nc_scale .scale_text2 {
  color: #ccc !important;
  /* 滑过时的字体颜色 */
}

:deep .nc_scale span {
  border: 0px solid #ccc !important;
  height: 40px !important;
  border-radius: 8px !important;
  line-height: 40px !important;
}

:deep .errloading {
  border: #faf1d5 0px solid !important;
  color: #ef9f06 !important;
}
.loginPanel .title {
  display: none !important;
}
</style>
